import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import axios from "axios";
import * as XLSX from "xlsx";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/gstr.css";


function GSTR3B() {
  const ID = Cookies.get("Login_id");

  const [reportData, setReportData] = useState([]);
  const [invData, setinvData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [report, setReport] = useState("");

  const fetchInvoiceReportDetails = () => {
    axios
      .get(`${config.base_url}/GSTR3B/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setinvData(res.data.invData);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          setStatus("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchInvoiceReportDetails();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
      status: status,
    };

    axios
      .post(`${config.base_url}/gstr3bCustomized/`, det)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          // setCNotedata(res.data.cNotedata);
          setOtherDetails(res.data.otherDetails);
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
          setStatus(res.data.filterStatus);
          setReport(res.data.report);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [1, 0.3, 1, 0.5],
      filename: "Report_gstr3b",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
        date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table1 = document.getElementById("reportTable1");
    const Table2 = document.getElementById('reportTable2');
    const ws1 = XLSX.utils.table_to_sheet(Table1);
    const ws2 = XLSX.utils.table_to_sheet(Table2);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Nature Of Supplies");
    XLSX.utils.book_append_sheet(wb, ws2, " inter-state supplies");
    XLSX.writeFile(wb, "Report__gstr3b" + date + ".xlsx");
}

  

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var stat = status;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            status: stat,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/share_GSTR3BReport_email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
     
    }
  }



  return (
    <>
      <FinBase />
      <div
        className="page-content mt-0 pt-0"
        id="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
               
                <div className="text-uppercase" id="headline" style={{ fontSize: '2em', fontWeight: 'bold' }}>
                  GSTR-3B REPORT
                </div>
              </center>
              <hr />
            </div>

            <div className="bar">
              <div className=" left d-flex justify-content-start">
                <div className="position-relative mr-2">
                  <button
                    className="btn btn-secondary"
                    onClick={toggleContent}
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <i className="fas fa-solid fa-gear"></i> Customize Report
                  </button>
                  <div id="contentDiv" className="salescontent">
                    <h6>Customize Report</h6>
                    <form
                      onSubmit={handleCustomize}
                      className="form reportCustomizeForm px-1"
                      method="get"
                    >
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>From</label>
                        <br />
                        <input
                          className="inputdate form-control"
                          type="date"
                          name="start_date"
                          id="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required={endDate != "" ? true : false}
                        />
                      </div>
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>To</label>
                        <br />
                        <input
                          type="date"
                          className="inputdate form-control"
                          name="end_date"
                          id="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required={startDate != "" ? true : false}
                        />
                      </div>
                    
                     
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="submit"
                          className="btn btn-outline-light w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Run Report
                        </button>
                        <button
                          type="reset"
                          onClick={toggleContent}
                          className="btn btn-outline-light ml-1 w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="right d-md-flex flex-md-row d-flex flex-column">
                <a
                  className="btn btn-outline-secondary text-grey fa fa-file"
                  role="button"
                  id="pdfBtn"
                  onClick={reportPDF}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  {" "}
                  &nbsp;PDF
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                  role="button"
                  id="printBtn"
                  onClick={printSection}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Print
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                  role="button"
                  id="exportBtn"
                  onClick={ExportToExcel}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Export
                </a>
                <div className="dropdown p-0 nav-item" id="shareBtn">
                  <li
                    className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                    data-toggle="dropdown"
                    style={{
                      height: "fit-content",
                      width: "fit-content",
                    }}
                  >
                    &nbsp;Share
                  </li>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "black" }}
                    id="listdiv"
                  >
                    <a
                      href={shareUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li
                        style={{
                          textAlign: "center",
                          color: "#e5e9ec",
                          cursor: "pointer",
                        }}
                      >
                        WhatsApp
                      </li>
                    </a>
                    <li
                      style={{
                        textAlign: "center",
                        color: "#e5e9ec",
                        cursor: "pointer",
                      }}
                      data-toggle="modal"
                      data-target="#shareToEmail"
                    >
                      Email
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card radius-15 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{otherDetails.cmpName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b>GSTR-3B REPORT</b>
                        </center>
                        {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                      </div>
                    </div>
                    

                    <div className="row px-1 py-1" >
                    <p className=" text-dark" style={{ marginTop: '40px' }}>1. Details of outward supplies and inward supplies liable to
    reverse
    charge</p>
                        

                      <div className="col-12">
                      <section className="product-area mt-2 py-1">
                      <table className="table table-responsive-md mt-4 table-hover" id="reportTable1"   style={{ marginBottom: '40px' }}
                      >
                            <thead>
                              <tr className="text-center">

                              <th  style={{ padding: '20px' }}>Nature Of Supplies</th>
                              <th  style={{ padding: '20px' }} className="text-center" >Total Taxable Value</th>
                              <th   style={{ padding: '20px' }} className="text-center" >Integrated Tax</th>
                              <th  style={{ padding: '20px' }} className="text-center">Central Tax</th>
                              <th   style={{ padding: '20px' }} className="text-center">State/UT Tax </th>
                              <th  style={{ padding: '20px' }}  className="text-center">Cess </th>
                              </tr>
                             
                            </thead>
                            <tbody>

                              <tr className="text-center" >
                             <td >(a) Outward taxable supplies(other than zero rated,nil rated and exempted)</td>

                              
                                <td style={{ textAlign: 'center',color: "black" }}>{otherDetails.total_tax_out}</td>
                                <td style={{ textAlign: 'center',color: "black" }}>0</td>
                                <td style={{ textAlign: 'center',color: "black" }}>{otherDetails.total_cgst}</td>
                                <td style={{ textAlign: 'center',color: "black" }}>{otherDetails.total_sgst}</td>
                                <td style={{ textAlign: 'center',color: "black" }}>0</td>
                              </tr>
                                   
                                <tr>
                                    <td >(b) Outward taxable supplies(zero
                                        rated)</td>
                                        <td style={{ textAlign: 'center',color: "black" }}>{otherDetails.total_zero_rated_value}</td>
                                <td style={{ textAlign: 'center',color: "black" }}>{otherDetails.total_zero_rated_igst}</td>
                                <td style={{ textAlign: 'center',color: "black" }}>{otherDetails.total_zero_rated_cgst}</td>
                                <td style={{ textAlign: 'center',color: "black" }}>{otherDetails.total_zero_rated_sgst}</td>
                                    <td>0</td>
                                    

                                </tr>
                                <tr>
                                    <td >(c) Other outward supplies(nil rated, exempted)</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>

                                </tr>
                                <tr>
                                    <td >(d) Inward supplies(liable to reverse charge)</td>
                                    <td>{otherDetails.unregistered_party_tax_amount }</td>
                                    <td>{otherDetails.unregistered_party_igst }</td>
                                    <td>{otherDetails.unregistered_party_cgst }</td>
                                    <td>{otherDetails.unregistered_party_sgst}</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '10px' }}>Total</th>
                                    <th>{otherDetails.grand_total }</th>
                                    <th>{otherDetails.grand_totali }</th>
                                    <th>{otherDetails.grand_totalc }</th>
                                    <th>{otherDetails.grand_totals}</th>
                                    <th>0</th>
                                </tr>

                            
                            </tbody>
                          </table>
                        </section>
                        <br />
                        <p className=" text-dark"  style={{ marginBottom: '45px' }}>2. Details of inter-state supplies made to unregisterd persons, composition dealer and UIN holders</p>

                     
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab1" data-title="Sale">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2" style={{ marginBottom: '50px' }}>
                            <thead>
                              <tr className="text-center">
                              <th rowSpan="2" style={{ textAlign: 'center',color: "black" }}>Place Of Supply(Name Of State)</th>

                                <th colSpan="4" style={{ textAlign: 'center',color: "black" }}>Supplies Made To Unregisterd Persons</th>
                                <th colSpan="4" style={{ textAlign: 'center',color: "black" }}> Supplies
                                Made To Composition Taxable Persons</th>

                              
                                <th colSpan="4" style={{ textAlign: 'center',color: "black" }}>Supplies
                                Made To UIN Holders</th>
                              </tr>
                              <tr className="text-center">
                              <th colSpan="2" style={{ textAlign: 'center',color: "black"}}>Total Taxable Value</th>
                              <th colSpan="2" style={{ textAlign: 'center',color: "black" }}>Amount Of Integrated Tax</th>

                              <th colSpan="2" style={{ textAlign: 'center',color: "black"}}>Total Taxable Value</th>
                              <th colSpan="2" style={{ textAlign: 'center',color: "black"}}>Amount Of Integrated Tax</th>
                              <th colSpan="2" style={{ textAlign: 'center',color: "black"}}>Total Taxable Value</th>
                              <th colSpan="2" style={{ textAlign: 'center',color: "black" }}>Amount Of Integrated Tax</th>

                                </tr>
                            </thead>
                            <tbody>
                            {reportData.map((item, index) => (
                  <tr className="text-center" key={index}>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.place_of_supply}</td>

                    {/* Supplies Made To Unregistered Persons */}
                    {item.category === 'unregistered' ? (
                      <>
                        <td colSpan="2" style={{ textAlign: 'center', color: 'black' }}>{item.subtotal}</td>
                        <td colSpan="2" style={{ textAlign: 'center', color: 'black' }}>{item.igst}</td>
                      </>
                    ) : (
                      <>
                        <td colSpan="2"></td>
                        <td colSpan="2"></td>
                      </>
                    )}

                    {/* Supplies Made To Composition Taxable Persons */}
                    {item.category === 'composition' ? (
                      <>
                        <td colSpan="2" style={{ textAlign: 'center', color: 'black' }}>{item.subtotal}</td>
                        <td colSpan="2" style={{ textAlign: 'center', color: 'black' }}>{item.igst}</td>
                      </>
                    ) : (
                      <>
                        <td colSpan="2"></td>
                        <td colSpan="2"></td>
                      </>
                    )}

                    {/* Supplies Made To UIN Holders (if applicable) */}
                    <td colSpan="2"></td>
                    <td colSpan="2"></td>
                  </tr>
                ))}
                                 
                            </tbody>
                          </table>
                        </section>
                        <br />
                        <p className="text-dark" style={{ marginBottom: '40px' }}>3. Details of eligible Input Tax Credit
                        </p>
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2" style={{ marginBottom: '40px' }}>
                          <thead>
                            <tr >
                                <th className="text-center " style={{ padding: '20px' }}>Details
                                </th>
                                <th className="text-center " style={{ padding: '20px' }}>Total
                                    Taxable
                                    Value</th>
                                <th className="text-center" style={{ padding: '20px' }}>
                                    Integrated Tax
                                </th>
                                <th className="text-center " style={{ padding: '20px' }}>Central
                                    Tax</th>
                                <th className="text-center " style={{ padding: '20px' }}>State/UT
                                    tax
                                </th>
                                <th className="text-center " style={{ padding: '20px' }}>Cess
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="text-center " colspan="6"
                                   >
                                    (A)ITC Available(whether
                                    in full of part)</th>

                            </tr>
                            <tr>
                                <td>(1) Import of goods</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                                <td>(2) Import of services</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>(3) Inward supplies liable
                                    to
                                    reverse charge(other tham 1
                                    & 2
                                    above)</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                                <td>(4) Inward supplies from ISD
                                </td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                                <td>(5) All other ITC</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                                <th className="text-center " colspan="6"
                                    >
                                    (D)Ineligible ITC</th>

                            </tr>
                            <tr>
                                <td>(1) As per section 17(5)
                                </td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>(2) Others</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                        </tbody>

                          </table>
                        </section>
                        <p className="text-dark" style={{ marginBottom: '40px' }}>4. Details of exempt ,nill rated and non-GST inward supplies
                        </p>
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2" style={{ marginBottom: '40px' }}>
                          <thead>
                            <tr >
                            <th className="text-center" style={{ padding: '20px' }}>Nature Of Supplies</th>
                                
                                <th className="text-center" style={{ padding: '20px' }}>
                                    Inter-state Supplies
                                </th>
                                <th className="text-center " style={{ padding: '20px' }}>Intra-state Supplies</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr>
                                <td>From a supplier under composition scheme,Exempt and Nill rated supply</td>
                                <td>0</td>
                                <td>0</td>
                                
                            </tr>
                            <tr>
                                <td>Non GST Supply</td>
                                <td>0</td>
                                <td>0</td>
                               

                            </tr>
                            
                        </tbody>

                          </table>
                        </section>
                       
                      </div>

                     </div>

                        <section className="balance-info text-dark pt-1 pb-1">
                          <div className="row p-4">
                            <div className="col-10">
                              <div className="row ">
                                <br/><br/><br/><br/><br/>
                                <div className="col-4 "></div>
                                <div className="col-4"></div>
                              </div>
                            </div>
                          </div>
                        </section>

                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GSTR3B;
